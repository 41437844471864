<template>
    <div>
            <div   class="pb-6 pb-300 w-100 position-absolute diagonal pt-2 pt-md-7">
                <div class="py-4 mt--4 mb--3">
                    <h6 class="h2 text-white d-inline-block mb-0">Manažment kampaní</h6>
                    <div class="text-right" style="float:right">
                        <button  @click="openCampaignModal" class="btn btn-primary">Nová kampaň <i class="fas fa-plus-circle" style="margin-left:5px;margin-right:-5px;"></i></button>
                    </div>
                </div>
            </div>
        <div class="container-fluid pt-150 ">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :rowKey="record => record.id"
                                    :dataSource="data"
                                    :loading="loading"
                                    class="table-flush">


                                <template slot="date" slot-scope="detailed">
                                    {{formatDateN(detailed)}}
                                </template>

                                <template slot="name" slot-scope="user">
                                    <router-link :to="'/edit-campaign/' + user.id" class="btn text-default btn-icon btn-secondary btn-sm" :href="'/edit-campaign/' + user.id"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</router-link>
                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(user.id)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                            Položka bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                    </a-popconfirm>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal title="Kampaň" centered  v-model="campaignModal"  @cancel="campaignModal = false" v-if="campaignModal" @ok="addCampaign">
            <template>
                <div class="mb-4">
                    <h6 class="heading-small text-muted mb-2">Názov kampane</h6>
                <a-input alternative=""
                            input-classes="form-control-alternative"
                            v-model="campaign.title"
                />
                </div>
                <div class="mb-4">
                    <h6 class="heading-small text-muted mb-2">Typ akcie</h6>
                    <a-select defaultValue="1" class=" w-100" v-model="campaign.type" :options="types"/>
                </div> <template>
                    <div class="mb-4">
                        <h6 class="heading-small text-muted mb-2">Platí pre izby</h6>
                        <a-select defaultValue="1" mode="multiple" class=" w-100" v-model="campaign.rooms" :options="contenttypes"/>
                    </div>
                </template>
                <div class="mb-4">
                    <h6 class="heading-small text-muted mb-2">Banner</h6>
                    <image-upload title="Baner s akciou" v-model="campaign.image" @changed="onChildChange"  dat="image"></image-upload>
                </div>
                <div class="mb-4">
                    <h6 class="heading-small text-muted mb-2">Termín platnosti</h6>
                    <a-range-picker class="mb-3"  v-model="campaign.valid"  :locale="locale" />
                </div>
                <div class="mb-4">
                    <h6 class="heading-small text-muted mb-2">Nákup izby v termíne</h6>
                    <a-range-picker class="mb-3"  v-model="campaign.daterange"  :locale="locale" />
                </div>
                <a-checkbox class="mb-3" v-model="campaign.active">
                    Kampaň aktívna
                </a-checkbox>
                <template slot="footer"  style="padding-top:0;">
                </template>
            </template>
        </a-modal>
    </div>
</template>
<script>
    import {dataService} from "../_services";
    import {Popconfirm,Modal} from "ant-design-vue"
    import moment from 'moment'
    import Image from "./content/Image";

    import FormItem from 'ant-design-vue'
    import Vue from "vue"
    Vue.use(FormItem);

    const columns = [
        {
            title: 'Kampaň',
            dataIndex: 'title',
            sorter: true,
        },
        {
            title: 'Platná od',
            dataIndex: 'validfrom',
            scopedSlots: {customRender: 'date'},
            sorter: true,
        },
        {
            title: 'Platná do',
            dataIndex: 'validto',
            scopedSlots: {customRender: 'date'},
            sorter: true,
        },
        {
            title: 'Typ',
            dataIndex: 'type',
            sorter: true,
        },
        {
            title: 'Aktívna',
            dataIndex: 'active',
            sorter: true,
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];

    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Termín od", "do"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storoťie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }

    export default {
        name: "campaigns",
        components: {
            "a-popconfirm": Popconfirm,
            'a-modal': Modal, 'image-upload' : Image
        },
        data() {
            return {
                data: [],
                campaignModal: false,
                loading: false,
                locale,
                types:[{"value":1,"label": "Akcia 2 + 1 zadarmo"},{"value":2,"label": "Služba v cene"}],
                contenttypes:[],
                campaign: {'title':'','image':''},
                colorsto: JSON.parse(localStorage.colors),
                columns,
            };
        },
        mounted() {
            this.fetch();
            dataService.axiosFetch("content","typy_izieb").then(results => {
                var roleops = [];
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });
                this.contenttypes = roleops;
            });

        },
        methods: {
            addCampaign()
            {
                dataService.axiosPost("addcampaign", this.campaign).then(results => {
                    this.fetch();
                });
              //  this.campaignModal = false;
            },
            onChildChange(value) {
                this.campaign.image = JSON.stringify(value);
            },
            openCampaignModal() {
this.campaignModal = true;
            },
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            cancel() {
            },
            formatDateN: function(dt){

                return moment(dt,'YYYY-MM-DD').format('D.M.YYYY')


            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch(this.$options.name).then(results => {
                    this.data = results;
                    this.loading = false;
                });
            }

        },
    };
</script>

<style>
    .image-editor .image-uploader,
    .image-editor .image-property
    {width:49%!important;}
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination-item.ant-pagination-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
